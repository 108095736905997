<template>
 <el-row :gutter="20">
  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
    <div class="heading"><h1>Buildings</h1></div>
  </el-col>
  <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
    <div class="topsearch">
      <el-input v-model="search" size="small" placeholder="Type to search by nmae"/>
      <el-button type="primary" @click="addnewBuilding()">Add Building</el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="deleteBuilding">
    <el-alert
      title="Delete Building Successfully!"
      type="success"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
  <el-table
    :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
    ref="multipleTable"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    :row-class-name="tableRowClassName"
    :header-cell-class-name ="tableCellClassName"
  >
    <el-table-column
      type="selection"
      width="55"
    />
    <el-table-column
        prop="logo_image"
      label="Photo"
      width="100"
    >
      <template v-slot="scope">
        <el-image
            style="width: 60px; height: 60px"
            :src="scope.row.logo_image">
        </el-image>
      </template>
    </el-table-column>
    <el-table-column
      prop="name"
      label="Building Name"
      sortable
    />
    <el-table-column
      prop="added_on"
      sortable
      label="Added Date"
      width="140"
    />
    <el-table-column
        align="right" width="200">
      <template #header>
          ACTIONS
      </template>
      <template v-slot="scope">
        <el-button  size="small" type="primary" icon="el-icon-edit">
          <router-link :to="{ name: 'editBuilding', params: { buildingId: scope.row.id }}">
            Edit
          </router-link>
        </el-button>
        <el-button
            size="small"
            type="danger" icon="Delete"
            @click="handleDelete(scope.$index, scope.row)"></el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total">
    </el-pagination>
</div>
</template>

<script>
import BuildingService from "@/api/building.service";

export default {
  name: "Building",
  data() {
    return {
      deleteBuilding: false,
      tableData: [],
      page: 1,
      pageSize: 5,
      total: 0,
      multipleSelection: [],
      search: '',
    }
  },
  mounted() {
    this.getBuildings()
  },
  methods: {
    async getBuildings() {
      //console.log("Fetch")
      return BuildingService.buildings(this.page, this.search).then(response => {
        this.total = response.data.data.length;
        this.tableData = response.data.data.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
      });
    },
    tableRowClassName({row, rowIndex}) {
        if (rowIndex %2 === 1) {
          return 'success-row';
        }
          return '';
      },
    tableCellClassName({row, column, rowIndex, columnIndex}){
         return 'tableHeading';
      },
    handleSizeChange(val) {
      this.pageSize=val;
       this.getBuildings(val);
      },
    handleCurrentChange(val) {
        this.page = val;
        this.getBuildings(val);
    },
    formatter(row, column) {
      return row.address;
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    addnewBuilding() {
      this.$router.push("/building/add");
    },
    handleDelete(indexno=null,deleterow=null){
      var result = confirm("Are you sure to delete this Building?");
      if (result) {
        return BuildingService.delete(deleterow).then(response => {
          this.deleteBuilding=true;
          this.getBuildings();
        });
    }
    }
  }
}
</script>
 <style lang="scss">
  .el-input--mini .el-input__inner {
    height: 40px;
}
</style>
<style lang="scss" scoped>
.topsearch .el-input {
    width: 500px;
    }
.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
  .el-table::v-deep(.tableHeading) {
    background: #bebbc936 !important;
    text-transform: uppercase;
    font-size :.657rem;
    letter-spacing :.5px;
    color:#6e6b7b;
    font-family: 'Montserrat,Helvetica,Arial,serif';
  }
.el-table .success-row {
    background: #f0f9eb61;
  }
 div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{
  padding: 12px 5px;
  float: right;
}
</style>
